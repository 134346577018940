import ng from 'angular';
import * as Types from '@/types';
import { TemplateSetupController } from '@/components/setup';
import {
    MachineModelService,
} from '@/services';
import {
    MachineSetup,
    MachineAdditionalInjectedRequests,
} from './machine-setup';
import { applyIfNeeded } from '@/services/helpers/angular-helpers';

type APISingleResponse<T> = Types.UI.APISingleResponse<T>;
type VirtualMachine = Types.MachineApi.VirtualMachine;

export class TemplateMachineSetupController extends TemplateSetupController {
    public static $inject: string[] = [
        '$injector',
        'machineModel',
    ];
    public machineSetup: MachineSetup;
    constructor(
        $injector: ng.auto.IInjectorService,
        private machineModel: MachineModelService,
    ){
        super(
            $injector,
        );
    }

    public $onInit(): void {
        this.pageHeaderData = {
            backwardLink: 'machine.dashboard',
            backwardText: this.$translate.instant('TR_140119-a258ef_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: {},
            panelIcon: 'box-alt',
            panelTitle: this.$translate.instant('18249206-2af0-4e97-abe6-51184efa0ce2'),
        };
        const additionalInjectionRequests: MachineAdditionalInjectedRequests = {
            loadVirtualMachine: (id: string): Promise<VirtualMachine> => {
                return this.machineModel.findOneById(id);
            },
            updateVirtualMachine: (virtualMachine: VirtualMachine): Promise<APISingleResponse<VirtualMachine>> => {
                return this.machineModel.updateMachine(virtualMachine);
            },
        }
        this.machineSetup = new MachineSetup(
            {...this.genericInjectionRequests, ...additionalInjectionRequests},
            (fn: () => void) => {
                applyIfNeeded(this.$rootScope, fn);
            });
        super.$onInitGeneric(this.machineSetup);
    }
}

export class TemplateMachineSetupComponent implements ng.IComponentOptions {
    public bindings = {
        orderId: '<',
    };
    public template = require('./machine-setup-template.html');
    public controller = TemplateMachineSetupController;
}
