import ng from 'angular';
import * as Types from '@/types';
import { TemplateSetupController, } from '@/components/setup';
import {
    DomainModelService,
} from '@/services';
import {
    DomainSetup,
    DomainAdditionalInjectedRequests,
} from './domain-setup';
import { applyIfNeeded } from '@/services/helpers/angular-helpers';

type APISingleResponse<T> = Types.UI.APISingleResponse<T>;
type Domain = Types.DomainApi.Domain;

export class TemplateDomainSetupController extends TemplateSetupController {
    public static $inject: string[] = [
        '$injector',
        'domainModel',
    ];

    public domainSetup: DomainSetup;

    constructor(
        $injector: ng.auto.IInjectorService,
        private domainModel: DomainModelService,
    ){
        super(
            $injector,
        );
   }

    public $onInit(): void {
        this.pageHeaderData = {
            backwardLink: 'domain.dashboard',
            backwardText: this.$translate.instant('TR_100119-28bdd0_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: {},
            panelIcon: 'box-alt',
            panelTitle: this.$translate.instant('18249206-2af0-4e97-abe6-51184efa0ce2'),
        };
        const additionalInjectionRequests: DomainAdditionalInjectedRequests = {
            loadDomain: (id: string): Promise<Domain> => {
                return this.domainModel.findOne(id);
            },
            updateDomain: (domain: Domain): Promise<APISingleResponse<Domain>> => {
                return this.domainModel.update(domain);
            },
        }
        this.domainSetup = new DomainSetup(
            {...this.genericInjectionRequests, ...additionalInjectionRequests},
            (fn: () => void) => {
                applyIfNeeded(this.$rootScope, fn);
            });

        super.$onInitGeneric(this.domainSetup);
    }
}

export class TemplateDomainSetupComponent implements ng.IComponentOptions {
    public bindings = {
        orderId: '<',
    };
    public template = require('./domain-setup-template.html');
    public controller = TemplateDomainSetupController;
}
