import * as ng from 'angular';
import './checkbox.scss';

export type CheckboxStatusChange = 'none' | 'added' | 'removed' | 'modified';

export class MoleculeFormCheckboxController implements ng.IController {
    public static $inject: string[] = ['$transclude'];

    public ngModel: boolean;
    public indeterminate = false;

    public checkboxIdentifier: string;
    public labelText: string;
    public modifier: string;
    public disabled: boolean;
    public stroke: boolean;
    public inputName: string;
    public statusChange: CheckboxStatusChange = 'none';

    public callbackOnClick: (arg0?: any) => any;
    public callbackOnClickParams: any;

    constructor(
        private $transclude: ng.ITranscludeFunction,
    ) {}

    public $onInit() {
        this.ngModel = this.ngModel || false;
        this.modifier = this.modifier || '';
        this.disabled = this.disabled || false;
        this.stroke = this.stroke || false;
        this.checkboxIdentifier = Math.random().toString(36).substring(2, 9);
    }

    public onCheckboxChange() {
        if (this.disabled) {
            return;
        }

        if (this.callbackOnClick !== undefined) {
            if (this.callbackOnClickParams !== undefined) {
                if (Array.isArray(this.callbackOnClickParams)) {
                    this.callbackOnClick.apply(this, this.callbackOnClickParams);
                } else {
                    this.callbackOnClick(this.callbackOnClickParams);
                }
            } else {
                this.callbackOnClick();
            }
        }
    }

    public keyUpEvent = (event) => {
        if (!this.disabled && event.keyCode === 13) { // ENTER
            this.ngModel = !this.ngModel;
            this.onCheckboxChange();
        }
    };

    public get labelTextTranscludeFilled() {
        return this.$transclude.isSlotFilled('labelText');
    }
}

export class MoleculeFormCheckboxComponent implements ng.IComponentOptions {
    public bindings = {
        callbackOnClick: '<',
        callbackOnClickParams: '<',
        disabled: '<',
        indeterminate: '<?',
        inputName: '@?',
        labelText: '@',
        modifier: '@?',
        ngModel: '=',
        statusChange: '@',
        stroke: '<',
    };
    public transclude = {
        labelText: '?labelText',
    };
    public controller =  MoleculeFormCheckboxController;
    public controllerAs = '$formcheckbox';
    public template = require('./checkbox.html');
}
