import * as Types from '@/types';
import ng, { IScope } from 'angular';
import { GenericInjectedRequests } from './setup';
import {
    AccountModelService,
    DepositModelService,
    OrchestratorRobotService,
    CacheService,
    FunctionRelayService
} from '@/services';
import { OrchestrationPoll } from '@/services/setup/orchestration-poll';
import { PanelHeaderData } from '@/atomic-components/molecules/panels';
import { GenericSetup } from '@/components/setup/setup';

type APISingleResponse<T> = Types.UI.APISingleResponse<T>;
type BankAccount = Types.BillingApi.BankAccount;
type PaymentMethod = Types.BillingApi.PaymentMethod;

export class TemplateSetupController {

    public pageHeaderData: PanelHeaderData;

    public orderId: string = '';
    public genericInjectionRequests: GenericInjectedRequests = {
        createBankAccount: (iban: string, accountHolder: string, bic: string): Promise<APISingleResponse<BankAccount>> => {
            return this.depositModel.bankAccountCreate(iban, accountHolder, bic);
        },
        checkPaymentMethodsAvailability: (): Promise<PaymentMethod[]> => {
            return this.depositModel.paymentMethodAvailability();
        },
        getBankAccount: (): Promise<BankAccount[]> => {
            return this.accountModel.ownBankAccountsFind();
        },
        refreshDeposit: (): void => {
            this.cache.clearAll();
            this.functionRelay.call('updateDeposit', {});
        }
    };

    protected accountModel: AccountModelService;
    protected depositModel: DepositModelService;
    protected orchestratorRobot: OrchestratorRobotService;
    protected $rootScope: IScope;
    protected $translate: ng.translate.ITranslateService;
    protected cache: CacheService;
    protected functionRelay: FunctionRelayService;

    constructor(
        protected $injector: ng.auto.IInjectorService,
    ){
        this.accountModel = $injector.get('accountModel');
        this.depositModel = $injector.get('depositModel');
        this.orchestratorRobot = $injector.get('orchestratorRobot');
        this.$rootScope = $injector.get('$rootScope');
        this.$translate = $injector.get('$translate');
        this.cache = $injector.get('cache');
        this.functionRelay = $injector.get('functionRelay');
    }

    public $onInitGeneric(setupInstance: GenericSetup): void {
        if (!this.pageHeaderData) {
            this.pageHeaderData = {
                backwardLink: 'old-dashboard',
                backwardText: this.$translate.instant('TR_090119-e73cf8_TR'),
                panelHeaderRoute: '',
                panelHeaderRouteParams: {},
                panelIcon: 'box-alt',
                panelTitle: this.$translate.instant('18249206-2af0-4e97-abe6-51184efa0ce2'),
            };
        }

        const orchestrationPoll = new OrchestrationPoll(this.orchestratorRobot, setupInstance);
        void orchestrationPoll.poll(this.orderId);

        setupInstance.loadInitialData();
    }
}
