import ng from 'angular';
import * as Types from '@/types';
import { TemplateSetupController } from '@/components/setup';
import {
    ManagedApplicationRobotService,
    StorageModelService,
    NavigationService,
} from '@/services';
import {
    StorageSetup,
    StorageAdditionalInjectedRequests,
} from './storage-setup';
import { applyIfNeeded } from '@/services/helpers/angular-helpers';

type APISingleResponse<T> = Types.UI.APISingleResponse<T>;
type Nextcloud = Types.ManagedApplicationApi.Nextcloud;

export class TemplateStorageSetupController extends TemplateSetupController {
    public static $inject: string[] = [
        '$injector',
        'storageModel',
        'navigation',
        'managedApplicationRobot'
    ];
    public productId: string;
    public storageSetup: StorageSetup;
    constructor(
        $injector: ng.auto.IInjectorService,
        private storageModel: StorageModelService,
        private navigation: NavigationService,
        private managedApplicationRobot: ManagedApplicationRobotService,
    ){
        super(
            $injector
        );
    }

    public $onInit(): void {
        this.pageHeaderData = {
            backwardLink: 'nextcloud.overview',
            backwardText: this.$translate.instant('TR_010819-62941c_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: {},
            panelIcon: 'box-alt',
            panelTitle: this.$translate.instant('18249206-2af0-4e97-abe6-51184efa0ce2'),
        };
        const additionalInjectionRequests: StorageAdditionalInjectedRequests = {
            loadNextcloud: (id: string): Promise<Nextcloud> => {
                return this.storageModel.findOne(id);
            },
            updateNextcloud: (storage: Nextcloud): Promise<APISingleResponse<Nextcloud>> => {
                return this.storageModel.nextcloudUpdate(storage);
            },
        }
        this.storageSetup = new StorageSetup(
            this.managedApplicationRobot,
            {...this.genericInjectionRequests, ...additionalInjectionRequests},
            (fn: () => void) => {
                applyIfNeeded(this.$rootScope, fn);
            });
        this.storageSetup.nextcloudPoll(this.productId);
        this.storageSetup.loadInitialData();
    }

    public goToNextcloudDashboard = () => {
        this.navigation.go('nextcloud.overview');
    };
}

export class TemplateStorageSetupComponent implements ng.IComponentOptions {
    public bindings = {
        productId: '<',
    };
    public template = require('./storage-setup-template.html');
    public controller = TemplateStorageSetupController;
}
