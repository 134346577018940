import ng from 'angular';
import * as Types from '@/types';
import { TemplateSetupController } from '@/components/setup'
import {
    WebspaceModelService,
    VhostModelService,
    BundleModelService,
    NavigationService,
    WebhostingRobotService,
    AuthContextService,
    DatabaseRobotService,
} from '@/services';
import { DatabaseUserObject } from '@/atomic-components/molecules/panels/panel-rows/panel-row-access/database/database-access';
import { applyIfNeeded } from '@/services/helpers/angular-helpers';
import './bundle-setup-template.scss';

import { BundleSetup, BundleAdditionalInjectedRequests } from './bundle-setup';

type APIResponse<T> = Types.UI.APIResponse<T>;
type APISingleResponse<T> = Types.UI.APISingleResponse<T>;
type Webspace = Types.WebhostingApi.Webspace;
type VHost = Types.WebhostingApi.VHost;
type Bundle = Types.BundleApi.Bundle;
type WebspaceUser = Types.WebhostingApi.User;
type Database = Types.DatabaseApi.Database;

export class TemplateSetupBundleController extends TemplateSetupController {
    public static $inject: string[] = [
        '$injector',
        'webspaceModel',
        'vhostModel',
        'bundleModel',
        'webhostingRobot',
        'databaseRobot',
        'navigation',
    ];

    // begin bundle name edit panel (only used when bundle and webspace are loaded)
    public bundleNameIsEditing = false; // enable section
    public bundleName: string;
    // end bundle name edit panel

    public currentOpenedPanel = '';

    // webspace User
    public accessLevels = {
        ftpAccess: { label: this.$translate.instant('TR_100119-ca0b1e_TR'), value: false },
        sshAccess: { label: this.$translate.instant('TR_100119-fecdf6_TR'), value: true },
        statsAccess: { label: this.$translate.instant('TR_100119-77f707_TR'), value: false },
    };
    public webspaceUserSshKey: string;
    public webspaceUserName: string = AuthContextService.user.name;
    public webspaceUserPassword: string;
    public webspaceUserFormIsValid: boolean;

    public databaseUserFormIsValid: boolean;
    public databaseUserPassword: string;
    public databaseUserName: string = AuthContextService.user.name;

    public bundleSetup: BundleSetup;

    constructor(
        $injector: ng.auto.IInjectorService,
        private webspaceModel: WebspaceModelService,
        private vhostModel: VhostModelService,
        private bundleModel: BundleModelService,
        private webhostingRobot: WebhostingRobotService,
        private databaseRobot: DatabaseRobotService,
        private navigation: NavigationService,
    ){
        super(
            $injector
        );
    }

    /*
        FUNCTIONS
    */

    public $onInit(): void {
        this.pageHeaderData = {
            backwardLink: 'bundle.dashboard',
            backwardText: this.$translate.instant('TR_080119-5c34c7_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: {},
            panelIcon: 'box-alt',
            panelTitle: this.$translate.instant('18249206-2af0-4e97-abe6-51184efa0ce2'),
        };

        const additionalInjectionRequests: BundleAdditionalInjectedRequests = {
            loadWebspace: (id: string): Promise<Webspace> => {
                return this.webspaceModel.findOne(id);
            },
            loadVHost: (id: string): Promise<VHost> => {
                return this.vhostModel.findOne(id);
            },
            loadBundle: (id: string): Promise<Bundle> => {
                return this.bundleModel.findOne(id);
            },
            updateWebspace: (webspace: Webspace): Promise<APISingleResponse<Webspace>> => {
                return this.webspaceModel.update(webspace);
            },
            updateBundle: (bundle: Bundle): Promise<APISingleResponse<Bundle>> => {
                return this.bundleModel.update(bundle);
            },
            createWebspaceUser: (user: WebspaceUser, password: string): Promise<APISingleResponse<WebspaceUser>> => {
                return this.webhostingRobot.createUser(user, password);
            },
            createDatabaseUser: (user: DatabaseUserObject, password: string): Promise<APISingleResponse<Database>> => {
                return this.databaseRobot.userCreate(user, password);
            },
            createDatabase: (database: Database): Promise<APISingleResponse<Database>> => {
                return this.databaseRobot.createDatabase(database);
            },
            listDatabase: (bundleId: string): Promise<APIResponse<Database>> => {
                return this.databaseRobot.listDatabases({field: 'bundleId', value: bundleId});
            },
        }

        this.bundleSetup = new BundleSetup(
            {
                ...this.genericInjectionRequests,
                ...additionalInjectionRequests,
            },
            (fn: () => void) => {
                applyIfNeeded(this.$rootScope, fn);
            }
        );

        super.$onInitGeneric(this.bundleSetup);
    }

    public onBundleNameEdit(): void {
        this.bundleNameIsEditing = true;
        this.bundleName = this.bundleSetup.bundleObject.name;
    }

    public saveBundleName(bundleName: string): void {
        this.bundleSetup.changeBundleName(bundleName).then(
            () => {
                applyIfNeeded(this.$rootScope, () => {
                    this.bundleNameIsEditing = false;
                    this.bundleName = undefined;
                });
            },
            () => {
                // Just catch the error, so we don't get unhandled rejection.
            },
        );
    }

    public cancelBundleName(): void {
        this.bundleNameIsEditing = false;
        this.bundleName = undefined;
    }

    public goToAppInstaller = () => {
        this.navigation.go('bundle.id.domains.id.app-install',
            {
                bundleId: this.bundleSetup.bundleObject.id,
                domainId: this.bundleSetup.vHostObject.id,
                vhost: 'vhost',
            }
        );
    };

    public onSaveWebspaceUser = () => {
        this.bundleSetup.createWebspaceUser(
            {
                name: this.webspaceUserName,
                sshKey: this.webspaceUserSshKey,
            },
            this.webspaceUserPassword,
            this.accessLevels,);
    };

    public onSaveDatabaseUser = () => {
        this.bundleSetup.createDatabaseUser(
            {
                accountId: AuthContextService.account.id,
                name: this.databaseUserName,
                password: this.databaseUserPassword,
                comments: '',
            },
            this.databaseUserPassword,
        )
    }

    /*
        GETTERS
    */
    public get disableWebspaceSaveButton() {
        if (this.bundleSetup.webspaceNameIsUpdating) {
            return true;
        }
        const accessSet = this.accessLevels.ftpAccess.value || this.accessLevels.sshAccess.value || this.accessLevels.statsAccess.value;
        return !this.webspaceUserPassword || !accessSet || !this.webspaceUserFormIsValid;
    }

    public get disableDatabaseSaveButton() {
        if (this.bundleSetup.databaseIsUpdating) {
            return true;
        }
        return !this.databaseUserPassword || !this.databaseUserFormIsValid;
    }

    get sshCommand(): string {
        return `ssh ${this.bundleSetup.firstCreatedWebspaceUser}@${this.bundleSetup.webspaceHostname} -p 2244`
    }

    public get accountDefaults(): Types.AccountApi.AccountDefaults {
        return AuthContextService.accountDefaults;
    }

    public get nextStepsPanelTitle(): string {
        return this.$translate.instant('TR_210319-e63db0_TR');
    }

    public get bundleId(): string {
        return this.bundleSetup.bundleObject?.id;
    }

    public get hasBundleId(): boolean {
        return ['', null, undefined].indexOf(this.bundleId) < 0;
    }
}

export class TemplateSetupBundleComponent implements ng.IComponentOptions {
    public bindings = {
        orderId: '<',
    };
    public template = require('./bundle-setup-template.html');
    public controller = TemplateSetupBundleController;
}
