import ng from 'angular';
import {
    AuthContextService,
    BillingRobotService,
    DocumentModelService,
} from '@/services';

export class MoleculePanelEditRowSepaController implements ng.IController {
    public static $inject: string[] = ['authContext', 'billingRobot', 'documentModel'];

    public validationErrorList: any[];
    public sepaData: { iban: string; bic: string };
    public hasOpenInvoices = false;
    public amountOpenInvoices = 0;
    public bankAccount: any;
    public readonlyMode: boolean;
    public loadData: boolean;

    public isCurrentlyPrepaid = this.authContext.billingSettings.paymentType === 'prepaid';

    private bicObsoleteCountries: string[] = [];

    constructor(
        private authContext: AuthContextService,
        private billingRobot: BillingRobotService,
        private documentModel: DocumentModelService,
    ) {
        this.billingRobot.directDebitCountriesFind()
            .then(
                (result) => this.bicObsoleteCountries = result.response.data
                    .filter((country: any) => !country.bicRequired)
                    .map((country: any) => country.country)
            );
    }

    public $onInit() {
        this.documentModel.getUnpaidInvoices().then(
            (response) => {
                this.amountOpenInvoices = response.pagination.entries;
                this.hasOpenInvoices = response.pagination.entries > 0;
            }
        );
    }

    public get bicIsRequired() {
        if (this.sepaData.iban.length < 2) {
            return false;
        }

        return this.bicObsoleteCountries.every(
            (country) => this.sepaData.iban.indexOf(country) !== 0
        );
    }
}

export class MoleculePanelEditRowSepaComponent implements ng.IComponentOptions {
    public bindings = {
        loadData: '<',
        readonlyMode: '=',
        sepaData: '=',
        validationErrorList: '='
    };
    public controller = MoleculePanelEditRowSepaController;
    public template = require('./panel-edit-row-sepa.html');
}
