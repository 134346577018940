import ng from 'angular';
import {
    AlertManagerService,
    AuthContextService,
    BillingRobotService,
    GetEntryPointService
} from '@/services';

export class OrganViewPanelDataProcessingBottomController {
    public static $inject: string[] = ['$translate', 'alertManager', 'authContext', 'billingRobot', 'getEntryPoint'];

    public allowSigning: boolean;

    public advMail: string;
    public showSupport: boolean;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private authContext: AuthContextService,
        private billingRobot: BillingRobotService,
        private getEntryPoint: GetEntryPointService
    ) {}

    public $onInit() {
        this.advMail = 'datenschutz@' + this.getEntryPoint.domain;
        this.showSupport = this.getEntryPoint.showSupportOptions;
        if ([undefined].indexOf(this.allowSigning) >= 0) {
            this.allowSigning = true;
        }
    }

    public get alreadySigned() {
        return this.authContext.account.billingSettings.dataProcessingAgreementSignedCurrentVersion;
    }

    public download = () => this.billingRobot.downloadAdv();

    public confirm = () => {
        this.billingRobot.createAdv().then(this._handleResponseSuccess, this._handleResponseError);
    };

    private _handleResponseSuccess = (res) => {
        this.alertManager.success(this.$translate.instant('TR_110119-3f7212_TR'));
    };

    private _handleResponseError = (err) => {
        this.alertManager.error(err);
    };
}

export class OrganViewPanelDataProcessingBottomComponent implements ng.IComponentOptions {
    public bindings = {
        allowSigning: '<'
    };
    public controller = OrganViewPanelDataProcessingBottomController;
    public template = require('./data-processing-bottom.html');
}
