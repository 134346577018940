import ng from 'angular';
import { UiRights } from '@/configuration';
import {
    AccountModelService,
    AlertManagerService,
    AuthContextService,
    AuthenticationRobotService,
    DateTimeWrapper,
    DepositModelService,
    NavigationService
} from '@/services';
import * as Types from '@/types';

export class OrganismBillingPaymentOptionsController {
    public static $inject: string[] = [
        '$timeout',
        '$translate',
        '$window',
        'accountModel',
        'alertManager',
        'authenticationRobot',
        'depositModel',
        'navigation'
    ];

    public paymentType: string;
    public paymentServiceType: string;
    public token: string|undefined;
    public payerId: string|undefined;
    public paymentId: string|undefined;
    public action: string|undefined;
    public loadBankAccountData: boolean;
    public hasBankAccountOnFile = true;
    public displayInReviewWarning = false;
    public displaySepaProhibited = false;
    public useDefaultPaymentMethod = false;
    public sepaData: Types.BillingApi.BankAccount = {
        accountHolder: '',
        bankName: '',
        bic: '',
        iban: ''
    };

    private availablePaymentMethods: string[] = [];
    private _blockPaymentChange = false;
    private latestBankAccount: Types.BillingApi.BankAccount = null;
    private _sepaPendingVerification = false;
    private _origData = {
        bankData: {
            accountHolder: '',
            bankName: '',
            bic: '',
            iban: ''
        } as Types.BillingApi.BankAccount,
        paymentType: ''
    };

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private $window: ng.IWindowService,
        private accountModel: AccountModelService,
        private alertManager: AlertManagerService,
        private authenticationRobot: AuthenticationRobotService,
        private depositModel: DepositModelService,
        private navigation: NavigationService
    ) {}

    public $onInit(): void {
        void this.depositModel.paymentMethodAvailability().then(
            (paymentMethodAvailability: {paymentMethod: string; available: boolean}[]) => {
                this.availablePaymentMethods = paymentMethodAvailability
                    .map((method) => method.paymentMethod);
            }
        );

        this.paymentType = AuthContextService.account.billingSettings.paymentType;
        this._origData.paymentType = ng.copy(this.paymentType);

        if (this.paymentType === 'postpaid' && AuthContextService.account.billingSettings.postpaidType === 'invoice') {
            this.paymentType = ng.copy(AuthContextService.account.billingSettings.postpaidType);
            this._origData.paymentType = ng.copy(AuthContextService.account.billingSettings.postpaidType);
        }

        void this._loadBankData()
            .then((data) => {
                this._origData.bankData = ng.copy(data);
            });

        this._checkPaymentMethodBlocked();
    }

    public set sepaPendingVerification(value: boolean) {
        if (value) {
            this._blockPaymentChange = true;
        }
        this._sepaPendingVerification = value;
    }
    public get sepaPendingVerification(): boolean {
        return this._sepaPendingVerification;
    }

    public set blockPaymentChange(value) {
        this._blockPaymentChange = value;
    }
    public get blockPaymentChange(): boolean {
        return this._blockPaymentChange;
    }

    public get showPaymentMethodPanel(): boolean {
        return this.availablePaymentMethods.length > 1;
    }

    public showPaymentRechargeDepositPanel = (): boolean => {
        return AuthContextService.isGranted(UiRights.BIL_DEPOSIT_FUND)
            && this.availablePaymentMethods.length >= 1
            && this.availablePaymentMethods.some((method) => method.toLowerCase() !== 'directdebit');
    };

    public paymentRedeemVoucher = (): boolean => {
        return ['external', 'company'].indexOf(this.paymentType.toLowerCase()) === -1;
    };

    public save = async (): Promise<void> => {
        let apiResponse;

        if (this.paymentType.toLowerCase() === 'invoice') {
            // this payment method can not be selected and since the user already has to be on invoice we just throw a
            // success message.
            void this.alertManager.success(this.$translate.instant('TR_090119-148f94_TR'));

            return;
        }

        if (this.paymentType.toLowerCase() === 'postpaid') {
            // delete BIC information, if bic is not required
            if ((/^DE/.test(this.sepaData.iban))) {
                delete this.sepaData.bic;
            }

            if (this.useDefaultPaymentMethod === true) {
                apiResponse = await this.depositModel.paymentMethodChange(this.latestBankAccount.id);
            } else {
                const bic = this.sepaData.bic && this.sepaData.bic.length > 0 ? this.sepaData.bic : undefined;

                apiResponse = await this.depositModel.bankAccountCreate(
                    this.sepaData.iban,
                    this.sepaData.accountHolder,
                    bic,
                );

                if (apiResponse.errors.length > 0) {
                    void this.$timeout(() => {
                        this.displaySepaProhibited = true;
                    });

                    // Prevent reloading.
                    return Promise.reject(false);
                } else {
                    apiResponse = apiResponse.response;
                }
            }
        } else if (this.paymentType.toLowerCase() === 'prepaid') {
            apiResponse = await this.depositModel.paymentMethodChange(null, 'prepaid', null);
        }

        const currentTime =  new DateTimeWrapper();
        currentTime.setToday();
        this.$window.localStorage.setItem('paymentMethodHasChanged', '' + currentTime.toUnixTimestamp());

        await this.authenticationRobot.refreshAccountData();

        // possible API status: active, inReview, toBeProcessed, toBeDeleted, rejected
        if (apiResponse.status !== undefined) {
            switch (apiResponse.status) {
                case 'toBeProcessed':
                case 'active':
                    void this.alertManager.success(this.$translate.instant('TR_090119-148f94_TR'));
                    this.navigation.reloadCurrentState();
                    this.displayInReviewWarning = false;
                    break;
                case 'rejected':
                    void this.alertManager.error(this.$translate.instant('TR_060819-a51ecc_TR'));
                    break;
                case 'inReview':
                    void this.alertManager.warning(this.$translate.instant('TR_050819-c0c94f_TR'));
                    this.displayInReviewWarning = true;
                    break;
                default:
                    void this.alertManager.success(this.$translate.instant('TR_090119-148f94_TR'));
                    break;
            }
        }
    };

    public cancelPaymentMethod = (): void => {
        this.paymentType = ng.copy(this._origData.paymentType);
        this.sepaData = ng.copy(this._origData.bankData);
    };

    public changedPaymentMethod = (change: string): void => {
        void this.$timeout(() => {
            if (change.toLowerCase() === 'postpaid') {
                void this._loadBankData();
            }
        }, 50);
    };

    public changeUseDefaultPaymentMethod = (useDefaultPaymentMethod: boolean): void => {
        if (useDefaultPaymentMethod) {
            this.sepaData.accountHolder = ng.copy(this._origData.bankData.accountHolder);
            this.sepaData.iban = ng.copy(this._origData.bankData.iban);
            this.sepaData.bic = ng.copy(this._origData.bankData.bic);
            this.sepaData.bankName = ng.copy(this._origData.bankData.bankName);
        }
    };

    private _loadBankData = () => {
        this.displayInReviewWarning = false;
        this.loadBankAccountData = true;

        return this.accountModel.ownBankAccountsFind()
            .then(
                (res) => {
                    const latestBankAccount: Types.BillingApi.BankAccount = res[res.length - 1];

                    if ([undefined].indexOf(latestBankAccount) === -1) {
                        this.latestBankAccount = res[res.length - 1];
                        this.sepaData.accountHolder = latestBankAccount.accountHolder;
                        this.sepaData.iban = latestBankAccount.iban;
                        this.sepaData.bic = latestBankAccount.bic;
                        this.sepaData.bankName = latestBankAccount.bankName;

                        if (latestBankAccount.status === 'inReview') {
                            this.displayInReviewWarning = true;
                        } else if (latestBankAccount.status === 'pendingVerification') {
                            this.sepaPendingVerification = true;
                        }
                    } else {
                        this.latestBankAccount = null;
                        this.hasBankAccountOnFile = false;
                    }

                    this.loadBankAccountData = false;

                    return latestBankAccount;
                }
            );
    };

    private _checkPaymentMethodBlocked = (): void => {
        const lastChange = this.$window.localStorage.getItem('paymentMethodHasChanged');

        if ([null, undefined].indexOf(lastChange) < 0) {
            const currentTimestamp =  new DateTimeWrapper();
            currentTimestamp.setToday();
            this.blockPaymentChange = !this.sepaPendingVerification
                && (currentTimestamp.toUnixTimestamp() - 60000) < parseInt(lastChange, 10);

            if (!this.blockPaymentChange) {
                this.$window.localStorage.removeItem('paymentMethodHasChanged');
            }
        }
    };
}

export class OrganismBillingPaymentOptionsComponent implements ng.IComponentOptions {
    public bindings = {
        action: '<?',
        payerId: '<?',
        paymentId: '<?',
        paymentServiceType: '@?',
        token: '<?',
        userPanelRight: '='
    };
    public controllerAs = '$editFormOrganism';
    public controller = OrganismBillingPaymentOptionsController;
    public template = require('./payment-options.html');
}
