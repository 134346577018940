import ng from 'angular';
import * as Types from '@/types';
import { TemplateSetupController } from '@/components/setup';
import {
    WebspaceModelService,
} from '@/services';
import {
    WebhostingSetup,
    WebhostingAdditionalInjectedRequests,
} from './webhosting-setup';
import { applyIfNeeded } from '@/services/helpers/angular-helpers';


type APISingleResponse<T> = Types.UI.APISingleResponse<T>;
type Webspace = Types.WebhostingApi.Webspace;

export class TemplateWebhostingSetupController extends TemplateSetupController {
    public static $inject: string[] = [
        '$injector',
        'webspaceModel',
    ];
    public webhostingSetup: WebhostingSetup;
    constructor(
        $injector: ng.auto.IInjectorService,
        private webspaceModel: WebspaceModelService,
    ){
        super(
            $injector,
        );
    }

    public $onInit(): void {
        this.pageHeaderData = {
            backwardLink: 'webhosting.dashboard',
            backwardText: this.$translate.instant('TR_140119-285630_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: {},
            panelIcon: 'box-alt',
            panelTitle: this.$translate.instant('18249206-2af0-4e97-abe6-51184efa0ce2'),
        };
        const additionalInjectionRequests: WebhostingAdditionalInjectedRequests = {
            loadWebspace: (id: string): Promise<Webspace> => {
                return this.webspaceModel.findOne(id);
            },
            updateWebspace: (webspace: Webspace): Promise<APISingleResponse<Webspace>> => {
                return this.webspaceModel.update(webspace);
            },
        }
        this.webhostingSetup = new WebhostingSetup(
            {...this.genericInjectionRequests, ...additionalInjectionRequests},
            (fn: () => void) => {
                applyIfNeeded(this.$rootScope, fn);
            });

        super.$onInitGeneric(this.webhostingSetup);
    }
}

export class TemplateWebhostingSetupComponent implements ng.IComponentOptions {
    public bindings = {
        orderId: '<',
    };
    public template = require('./webhosting-setup-template.html');
    public controller = TemplateWebhostingSetupController;
}
