import ng from 'angular';
import { SepaData } from '../../setup';
import {
    AuthenticationRobotService,
    AlertManagerService,
} from '@/services';

export class TemplateSetupSepaPanelController {
    public static $inject: string[] = [
        'authenticationRobot',
        'alertManager',
        '$translate',
    ]
    constructor(
        private authenticationRobot: AuthenticationRobotService,
        private alertManager: AlertManagerService,
        private $translate: ng.translate.ITranslateService,
    ){}

    public sepaStatus: string;
    public sepaFormIsValid: boolean = false;
    public sepaDataInput: SepaData = {iban: '', bic: '', accountHolder: '' };

    public paymentMethodIsUpdating: boolean;
    public saveSepaPaymentMethod: (
        sepaData: SepaData,
    ) => Promise<void>;

    public get getSepaStatus(): string {
        return this.sepaStatus;
    }

    get disableSepaSaveButton(): boolean {
        if (this.paymentMethodIsUpdating) {
            return true;
        }

        if (this.sepaDataInput?.iban === '' || this.sepaDataInput?.accountHolder === '' || !this.sepaFormIsValid) {
            return true;
        }
        return false;
    }

    public onSaveSepa = () => {
        this.saveSepaPaymentMethod(
            this.sepaDataInput,
        ).then(() => {
            this.authenticationRobot.refreshAccountData();
            void this.alertManager.success(this.$translate.instant('TR_090119-148f94_TR'));
        }).catch(() => this.paymentMethodIsUpdating = false);
    }

    public get sepaPanelTitle(): string {
        return this.$translate.instant("TR_080819-96f337_TR");
    }

    public get sepaHint(): string {
        return this.$translate.instant("TR_141024-132182_TR");
    }

    public get isActivatedOrPending(): boolean {
        return (this.getSepaStatus === 'pendingVerification' || this.getSepaStatus === 'active');
    }
}

export class TemplateSetupSepaPanelComponent implements ng.IComponentOptions {
    public bindings = {
        sepaStatus: '<',
        paymentMethodIsUpdating: '=',
        saveSepaPaymentMethod: '=',
    };
    public template = require('./sepa-panel.html');
    public controller = TemplateSetupSepaPanelController;
}
